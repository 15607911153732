@font-face {
font-family: '__saans_f840a3';
src: url(/_next/static/media/a83f985f2542505f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: regular;
}

@font-face {
font-family: '__saans_f840a3';
src: url(/_next/static/media/d48420a34cac0a2c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 570;
font-style: medium;
}

@font-face {
font-family: '__saans_f840a3';
src: url(/_next/static/media/d72fb3a121646634-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 670;
font-style: semibold;
}@font-face {font-family: '__saans_Fallback_f840a3';src: local("Arial");ascent-override: 91.04%;descent-override: 22.51%;line-gap-override: 0.00%;size-adjust: 99.96%
}.__className_f840a3 {font-family: '__saans_f840a3', '__saans_Fallback_f840a3'
}.__variable_f840a3 {--font-family-saans: '__saans_f840a3', '__saans_Fallback_f840a3'
}

